/**
 * クラス名：システム全体の共通Modal
 * 説明：システム全体に共通使っているModalである。
 * 作成者：ナンス
 * 作成日：2024/11/20
 * バージョン：1.0
 */
import { Modal, Button, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import confirm_icon from '../../assets/images/confirm_icon.png';
import '../../assets/styles/common.css';
import Draggable from 'react-draggable';
import { iQxRealGoku_AccessToken, iQxRealGoku_LoginUser, loginPath } from './Constant';

/* 確認メッセージのタイトル */
export const confirmModalTitle = (
  <div
    style={{
      width: 400,
      backgroundColor: '#005fab',
      marginLeft: -24,
      height: 36,
      marginTop: -20,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      fontSize: 14,
    }}
  >
    <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
  </div>
);
/* 画面切替警告メッセージのタイトル */
export const confirmScreenChangeTitle = (
  <div
    style={{
      width: 400,
      backgroundColor: '#005fab',
      marginLeft: -24,
      height: 36,
      marginTop: -20,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      fontSize: 14,
      display: 'flex',
    }}
  >
    <Image preview={false} width={20} src={confirm_icon} style={{ marginTop: 9, marginLeft: 7 }}></Image>
    <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>確認</p>
  </div>
);
export const confirmScreenChangeWarning = (
  <div
    style={{
      width: 500,
      backgroundColor: '#e31212',
      marginLeft: -24,
      height: 36,
      marginTop: -20,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      fontSize: 14,
      display: 'flex',
    }}
  >
    <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>！注意</p>
  </div>
);
/* 確認メッセージ内容 */
export const confirmUpdateContent = <p style={{ fontSize: '13.5px' }}>データを更新します。よろしいでしょうか。</p>;
export const confirmDiscardContent = <p style={{ fontSize: '13.5px' }}>データを破棄します。よろしいでしょうか。</p>;
export const confirmDeleteContent = <p style={{ fontSize: '13.5px' }}>データを削除します。よろしいでしょうか。</p>;
export const confirmCreateContent = <p style={{ fontSize: '13.5px' }}>データを登録します。よろしいでしょうか。</p>;
export const confirmSaveContent = <p style={{ fontSize: '13.5px' }}>データを保存します。よろしいでしょうか。</p>;
/* 画面切替警告メッセージ内容 */
export const confirmScreenChangeContent = (
  <p style={{ fontSize: '13.5px' }}>画面切り替えで当画面のデータが破棄されます。よろしいでしょうか。</p>
);

/* 共通ModalFooter */
export const commonFooter = (onOk, onCancel) => [
  <Button key="submit3" type="primary" onClick={onOk} className="mainButton">
    OK
  </Button>,
  <Button key="back" onClick={onCancel} className="cancelButton">
    キャンセル
  </Button>,
];
export const dataConfirmOK = (onOk) => [
  <Button key="submit3" type="primary" onClick={onOk} className="mainButton">
    OK
  </Button>,
];

/* 共通削除ModalFooter */
export const commonDeleteFooter = (onOk, onCancel) => [
  <div className="deleteFooter">
    <Button danger key="submit4" type="primary" onClick={onOk} className="mainButton deleteOKBtn">
      削除
    </Button>
    ,
    <Button key="back" onClick={onCancel} className="cancelButton">
      キャンセル
    </Button>
  </div>,
];

/* 共通ModalFooter */
export const commonActionFooter = (onOk, onCancel, okName, cancelName) => [
  <Button key="submit5" type="primary" onClick={onOk} className="mainButton">
    {okName}
  </Button>,
  <Button key="back" onClick={onCancel} className="cancelButton">
    {cancelName}
  </Button>,
];
// 共通コンポーネントModal
export const commonModal = (
  isOpen,
  title,
  footer,
  bodyStyle,
  width,
  onOk,
  onCancel,
  content,
  className,
  style,
  centered
) => {
  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title={title}
      footer={footer}
      bodyStyle={bodyStyle}
      width={width}
      onOk={onOk}
      onCancel={onCancel}
      closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      className={className}
      style={style}
      centered={centered}
      modalRender={(modal) => (
        <Draggable handle=".ant-modal-header">
          <div>{modal}</div>
        </Draggable>
      )}
    >
      {content}
    </Modal>
  );
};
/* 共通Modalタイトル */
export const modalTitle = (title, size, marginLeft) => {
  return (
    <div
      style={{
        width: size,
        backgroundColor: '#005fab',
        marginLeft: marginLeft,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{title}</p>
    </div>
  );
};
// エラーポップアップ
export const error = (content) => {
  return Modal.error({
    width: 465,
    className: 'modal-error',
    title: 'エラー',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
  });
};

// 完了ポップアップ
export const complete = (content) => {
  return Modal.success({
    width: 465,
    className: 'modal-complete',
    title: '完了',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
  });
};
// 出力完了ポップアップ
export const exportComplete = (content) => {
  return Modal.success({
    width: 300,
    className: 'modal-complete',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
  });
};
// トークンの認証エラーポップアップ
export const authorizeError = (content, isLogin = false) => {
  return Modal.error({
    width: 465,
    className: 'modal-error',
    title: 'エラー',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
    onOk() {
      localStorage?.removeItem(iQxRealGoku_LoginUser);
      localStorage?.removeItem(iQxRealGoku_AccessToken);
      if (!isLogin) window.location.replace(loginPath); // ログインへ遷移
    },
  });
};
