/**
 * クラス名：見積り済みの履歴画面
 * 説明：見積りを成功した履歴情報を表示。
 * 作成者：ナンス
 * 作成日：2024/11/18
 * バージョン：1.0
 */
import { Input, Button, Image, Row, Col, Table, Layout, DatePicker } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { WithRouter } from '../components/WithRouter';
import dayjs from 'dayjs';

import '../assets/styles/common.css';
import iq3Img_pmx1_1 from '../assets/images/iq3Img_pmx1_1.png';
import EstimateFooter from '../components/EstimateFooter';
import { formatDate } from './common/Common';

const EstimateHistory = forwardRef((props, ref) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [estimateDate, setEstimateDate] = useState('');
  const [fileNm, setFileNm] = useState('');
  const [estimateHistoryList, setEstimateHistoryList] = useState([]);
  const [estimateHistoryListClr, setEstimateHistoryListClr] = useState([]);

  const data = [
    {
      key: '1',
      estimateContent: { fileNm: 'D20241120.pmx', created: '2024-11-18' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '2',
      estimateContent: { fileNm: 'E20241120.pmx', created: '2024-11-19' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '3',
      estimateContent: { fileNm: 'C20241120.pmx', created: '2024-11-20' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '4',
      estimateContent: { fileNm: 'J20241120.pmx', created: '2024-11-21' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '5',
      estimateContent: { fileNm: 'G20241120.pmx', created: '2024-11-22' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '6',
      estimateContent: { fileNm: 'D20241120.pmx', created: '2024-11-23' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '7',
      estimateContent: { fileNm: 'A20241120.pmx', created: '2024-11-24' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
    {
      key: '8',
      estimateContent: { fileNm: 'B20241120.pmx', created: '2024-11-25' },
      quantity: 1,
      estimateCond: {
        cls: '案件',
        materialNm: 'SPCC',
        process: 'メッキ',
        diff: 'A級',
        level: '#40',
        endUse: '内部部品',
      },
      estimateAmt: 1000,
      status: '見積もり済み',
    },
  ];

  useEffect(() => {
    setEstimateHistoryList(data);
    setEstimateHistoryListClr(data);
  }, []);

  // 表示ボタンの押下
  const handleSearch = async () => {
    let filterDatas = JSON.parse(JSON.stringify(estimateHistoryListClr));
    if (fileNm !== '') {
      filterDatas = estimateHistoryListClr?.filter((item) => item.estimateContent.fileNm?.includes(fileNm));
    }
    if (estimateDate !== '') {
      filterDatas = estimateHistoryListClr?.filter((item) => formatDate(item.estimateContent.created) === estimateDate);
    }
    setEstimateHistoryList(filterDatas);
  };

  // 検索をクリアボタンの押下
  const handleClearSearch = async () => {
    let filterDatas = JSON.parse(JSON.stringify(estimateHistoryListClr));
    setEstimateHistoryList(filterDatas);
    setFileNm('');
    setEstimateDate('');
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const columns = [
    {
      title: '見積内容',
      dataIndex: 'estimateContent',
      width: 250,
      render: (record) => (
        <div style={{ display: 'flex', paddingLeft: 10 }}>
          <div style={{ display: 'grid' }}>
            <Image preview={false} width={100} src={iq3Img_pmx1_1}></Image>
          </div>
          <div style={{ display: 'grid', marginLeft: 10 }}>
            <label className="history-tb-label">アップロードファイル名：</label>
            <label className="history-tb-label">{record.fileNm}</label>
            <label className="history-tb-label">見積日：</label>
            <label className="history-tb-label">{record.created ? formatDate(record.created) : ''}</label>
          </div>
        </div>
      ),
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: 70,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{record}</label>
        </div>
      ),
    },
    {
      title: '見積条件',
      dataIndex: 'estimateCond',
      width: 220,
      render: (record) => (
        <div style={{ display: 'grid', padding: 7 }}>
          <label className="history-tb-label">発注分類：{record.cls}</label>
          <label className="history-tb-label">材質：{record.materialNm}</label>
          <label className="history-tb-label">処理：{record.process}</label>
          <label className="history-tb-label">公差：{record.diff}</label>
          <label className="history-tb-label">仕上げレベル：{record.level}</label>
          <label className="history-tb-label">最終用途：{record.endUse}</label>
        </div>
      ),
    },
    {
      title: '見積金額',
      dataIndex: 'estimateAmt',
      width: 220,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label style={{ fontWeight: 'bold' }}>{record + '円（' + record + '円/個）'}</label>
        </div>
      ),
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      width: 200,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label className="history-tb-label">{record}</label>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 0 }}>
      <Layout className="history-layout">
        <Layout className="history-layout-tb">
          <div
            style={{
              display: 'grid',
              width: '1800px',
              margin: '0px auto',
              paddingTop: 5,
            }}
          >
            <label style={{ fontSize: '20px', fontWeight: '400', color: '#333333' }}>見積履歴</label>
          </div>
          <div
            style={{
              display: 'grid',
              padding: 0,
              marginBottom: 10,
              width: '1800px',
              margin: '5px auto',
            }}
          >
            <div
              style={{
                display: 'grid',
                padding: 10,
                marginBottom: 0,
                borderRadius: 20,
              }}
            >
              <Row>
                <Col span={7}>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>
                    アップロードファイル名
                  </label>
                  <Input
                    id="fileNm"
                    name="fileNm"
                    value={fileNm}
                    style={{ marginLeft: 0, width: 250, marginLeft: 10 }}
                    allowClear
                    onChange={(e) => {
                      setFileNm(e.target.value);
                    }}
                  />
                </Col>
                <Col span={4}>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>見積日</label>
                  <DatePicker
                    locale="ja"
                    format={'YYYY年MM月DD日'}
                    style={{ marginLeft: 0, marginLeft: 10 }}
                    value={estimateDate ? dayjs(estimateDate, 'YYYY-MM-DD') : ''}
                    onChange={(_, dateString) => {
                      setEstimateDate(dateString);
                    }}
                  />
                </Col>
                <Col span={10} style={{ textAlign: 'left' }}>
                  <Button key="login" className="history-search-btn" onClick={(e) => handleSearch()}>
                    表示
                  </Button>
                  <Button
                    key="login"
                    className="history-search-btn"
                    style={{ marginLeft: 10, width: 100, backgroundColor: '#0958d9d1', color: 'white' }}
                    onClick={(e) => handleClearSearch()}
                  >
                    検索をクリア
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <div style={{ overflowY: 'scroll', textAlign: 'center' }}>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              dataSource={estimateHistoryList}
              className="history-tb"
              pagination={false}
            />
          </div>
        </Layout>
        <EstimateFooter selectedRows={selectedRows} page={'history'} updateActionType={props?.updateActionType} />
      </Layout>
    </div>
  );
});

export default WithRouter(EstimateHistory);
