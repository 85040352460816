/**
 * クラス名：システム全体の共通API接続ファイル
 * 説明：システム全体にAPIと接続する処理を共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/12/09
 * バージョン：1.0
 */
import { StatusCodes } from 'http-status-codes';
import { authorizeError, error } from './CommonModal';
import { ErrorMessage } from './Message';
import { getAccessToken, updateAccessToken } from './Common';
import { iQxRealGoku_AccessToken, iQxRealGoku_LoginUser, loginPath } from './Constant';

// ログイン認証処理
export const login = async (loginId, password, remember) => {
  let loginInfo = { loginId: 0, userName: '', updateToken: '' };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/Login';
    const otherParam = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        loginId: loginId,
        password: password,
        rememberMe: remember,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then(async (data) => {
        if (data && data?.statusCode === StatusCodes.OK) {
          loginInfo.loginId = data?.loginId;
          loginInfo.userName = data?.userName;
          loginInfo.updateToken = data?.updateToken;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode], true);
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          // 異常の場合、
          error(ErrorMessage()[data?.messageCode]);
        }
      })
      .catch((error) => {
        // 異常の場合、
        error(ErrorMessage().E010);
      });
  } catch (error) {
    // 異常の場合、
    error(ErrorMessage().E003);
    return error;
  }
  return loginInfo;
};

// ログイン後にユーザーを自動的にホーム画面にリダイレクトする処理のため、APIでトークン有効チェック処理
export const isAlive = async (token) => {
  let ret;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/IsAliveToken';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then(async (data) => {
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage()[data?.messageCode];
        }
      })
      .catch((error) => {
        // 異常の場合、
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    // 異常の場合、
    ret = error.message;
  }
  return ret;
};

// トークン有効情報でログインユーザーの情報を取得する
export const getCurrentUserInfo = async (accessToken) => {
  let ret = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/CurrentUserInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return await response?.json();
        }
      })
      .then((data) => {
        if (data) {
          // セクションにログインユーザー情報を保持する
          // Access value associated with the key
          let tokenString = JSON.stringify(data);
          localStorage.setItem(iQxRealGoku_LoginUser, encodeURI(tokenString));
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage().E004;
        }
      })
      .catch((error) => {
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    ret = error.message;
  }
  return ret;
};

//　ログアウト処理
export const logOut = async () => {
  let result = true;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/Logout';
    const token = getAccessToken();
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then((response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return response;
        }
      })
      .then((data) => {
        if (data && data?.status === StatusCodes.OK) {
          localStorage?.removeItem(iQxRealGoku_LoginUser);
          localStorage?.removeItem(iQxRealGoku_AccessToken);
          window.location.replace(loginPath); // ログインへ遷移
        } else if (data && data?.status !== StatusCodes.OK) {
          error(data);
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return result;
};

// Cad3d情報の取得
export const getCad3dDatas = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let cad3dDatas = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/GetCad3dDatas';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cad3dDatas.data = data?.data;
          // cad3dDatas.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cad3dDatas;
};
