/**
 * クラス名：Footer画面
 * 説明：注文、交渉と見積書の出力を行う。
 * 作成者：ナンス
 * 作成日：2024/11/18
 * バージョン：1.0
 */
import { Button, Space } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { WithRouter } from './WithRouter';
import { PDFDocument, rgb } from 'pdf-lib';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'; // ファイル保存用ライブラリ

import '../assets/styles/common.css';
import { Footer } from 'antd/es/layout/layout';
import { JPYs } from '../views/common/Common';

const EstimateFooter = forwardRef((props, ref) => {
  const pdfTemplateURL = '/pdf_template/pdf_ver1.0.pdf';
  // const pdfTemplateURL = '/pdf_template/test.pdf';
  useEffect(() => {
    // isActiveToken(); // ログインのトークン有効チェック
  }, []);

  // チェックを付けた見積りをまとめて注文する
  const handleOrder = async () => {
    props?.updateActionType('order');
    props.navigate('/home/:process-complete-msg');
  };

  // チェックを付けた見積りをまとめて金額交渉する
  const handleAdjustAmt = async () => {
    props?.updateActionType('adjust');
    props.navigate('/home/:process-complete-msg');
  };

  // 見積書（pdf版）ダウンロード処理
  const handleDownloadPdf = async () => {
    // 1. PDFをロード
    const templateBytes = await fetch(pdfTemplateURL).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(templateBytes);
    const form = pdfDoc.getForm();
    let estimateTotal = 0;
    props?.selectedRows?.forEach((row, i) => {
      let index = i + 1;
      const textField = form.getTextField('productNm' + index);
      textField.setText(row.detail.fileNm);
      textField.enableReadOnly();

      const amtTextField = form.getTextField('total' + index);
      amtTextField.setText(JPYs.format(row.detail.estimateAmt.toString()));
      amtTextField.enableReadOnly();

      estimateTotal += row.detail.estimateAmt;
    });

    const amtTotal = form.getTextField('total');
    amtTotal.setText(JPYs.format(estimateTotal.toString()));
    amtTotal.enableReadOnly();

    form.flatten();

    // 編集した PDF をダウンロード可能に
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    saveAs(blob, '見積書.pdf');
  };

  // チェックを付けた見積りの部品一覧取得
  const XLSX = require('xlsx');
  const ExcelJS = require('exceljs');
  const handleDownloadEstimateList = async () => {
    let tbHeaders = props?.columns?.filter((item) => item.title != '画像' && item.title != '操作');
    // 1. JSONデータをワークシートに変換
    // 新しいワークブックの作成
    const workbook = new ExcelJS.Workbook();
    // 新しいシートの追加
    const worksheet = workbook.addWorksheet('部品一覧');
    const headerRow = worksheet.getRow(1);
    headerRow.getCell(1).value = 'No.';
    for (let i = 0; i < tbHeaders?.length; i++) {
      let data = tbHeaders[i].title;
      let colNumber = i + 1;
      headerRow.getCell(colNumber).value = data;
    }
    headerRow.commit();
    // リスト
    let curRow = [];
    for (let i = 0; i < props?.selectedRows?.length; i++) {
      let data = props?.selectedRows[i];
      curRow = worksheet.getRow(i + 2);
      headerRow.eachCell(function (cell, colNumber) {
        if (cell.value == 'No') {
          curRow.getCell(colNumber).value = i + 1;
        } else if (cell.value == 'ファイル名') {
          curRow.getCell(colNumber).value = data.detail.fileNm;
        } else if (cell.value == '状態') {
          curRow.getCell(colNumber).value = data.detail.status;
        } else if (cell.value == '見積金額') {
          if (data.detail.estimateResult === 3) {
            curRow.getCell(colNumber).value = data.detail.estimateAmt + '円（' + data.detail.estimateAmt + '円/個）';
          } else {
            curRow.getCell(colNumber).value = data.detail.estimateResult;
          }
        }
      });
      curRow.commit();
    }

    // ファイルを保存
    let uint8Array = await workbook.xlsx.writeBuffer();
    let blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let excelName = '部品一覧書.xlsx'; // IQX_WEBEST-365 クイック印刷機能が実装していない。
    link.download = excelName;
    link.click();
    link.remove();
  };

  return (
    <Footer className="footer-layout">
      <div className="footer-layout-div">
        <Space size="large">
          {/* チェックを付けた見積りをまとめて注文する */}
          <Button
            disabled={props?.selectedRows?.length === 0 ? true : false}
            key="historyBtn1"
            className={props?.selectedRows?.length === 0 ? 'history-btn-disabled' : 'history-btn'}
            onClick={(e) => handleOrder()}
          >
            <p>
              チェックを付けた見積り
              <br />
              をまとめて注文する
            </p>
          </Button>
          {/* チェックを付けた見積りをまとめて金額交渉する */}
          <Button
            disabled={props?.selectedRows?.length === 0 ? true : false}
            key="historyBtn2"
            className={props?.selectedRows?.length === 0 ? 'history-btn-disabled' : 'history-btn'}
            onClick={(e) => handleAdjustAmt()}
          >
            <p>
              チェックを付けた見積り
              <br />
              をまとめて金額交渉する
            </p>
          </Button>
          {/* チェックを付けた見積りの見積書取得 */}
          <Button
            disabled={props?.selectedRows?.length === 0 ? true : false}
            key="historyBtn3"
            className={props?.selectedRows?.length === 0 ? 'history-btn-disabled' : 'history-btn'}
            onClick={(e) => {
              handleDownloadPdf();
            }}
          >
            <p>
              チェックを付けた見積り
              <br />
              の見積書取得
            </p>
          </Button>
          {/* チェックを付けた見積りの部品一覧取得 */}
          <Button
            disabled={props?.selectedRows?.length === 0 ? true : false}
            key="historyBtn4"
            className={props?.selectedRows?.length === 0 ? 'history-btn-disabled' : 'history-btn'}
            onClick={(e) => {
              handleDownloadEstimateList();
            }}
          >
            <p>
              チェックを付けた見積り
              <br />
              の部品一覧取得
            </p>
          </Button>
        </Space>
      </div>
    </Footer>
  );
});

export default WithRouter(EstimateFooter);
