/**
 * クラス名：TOP＆見積・注文画面
 * 説明：見積・注文を行う。
 * 作成者：ナンス
 * 作成日：2024/11/20
 * バージョン：1.0
 */
import { Button, Image, Table, Layout, Select, Input, Spin, Space, Tooltip } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';

import '../assets/styles/common.css';
import upload_file from '../assets/images/upload_file.png';
import EstimateFooter from '../components/EstimateFooter';
import {
  ToleranceClass,
  EstimateType,
  FinalPurposClass,
  MaterialClass,
  DeliveryTimeClass,
  OrderClass,
  Painting,
  Plating,
  ProcessType,
  UploadStatus,
  WeldFinishLevelClass,
} from './common/enums';
import Estimate from './Estimate';
import { WithRouter } from '../components/WithRouter';
import copy from '../assets/images/copy.png';
import paste from '../assets/images/paste.png';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { formatDate } from './common/Common';
import { allowedExtensions } from './common/Constant';
import { getCad3dDatas } from './common/CommonAPI';

const Home = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [cad3dDataLst, setCad3dDataLst] = useState([]);
  const excelFileRef = React.useRef(null);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [copyEstimateCondition, setCopyEstimateCondition] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [estimateOpen, setEstimateOpen] = useState(false);
  const estimateConditionDefault = {
    order: 0,
    quantity: '',
    material: 0,
    process: 0,
    processDetail: 0,
    tolerance: 0,
    weldFinishLevel: 0,
    finalPurpos: 0,
    deliveryTimeCls: 0,
    deliveryDate: new Date(),
    processDetailOptions: Plating,
    comment: '',
  };
  const data = [
    {
      id: 1,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 0, estimateResult: 3 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 2,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 0, estimateTotalPrice: 0, estimateResult: 0 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 3,
      fileImg: '',
      detail: {
        fileNm: 'D46002200.STEP',
        status: 1,
        estimateTotalPrice: 0,
        estimateResult: 0,
      },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 4,
      fileImg: '',
      detail: {
        fileNm: 'D46002200.STEP',
        status: 2,
        estimateTotalPrice: 0,
        estimateResult: 0,
      },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 5,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 0, estimateResult: 1 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 6,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 0, estimateResult: 2 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 7,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 2000, estimateResult: 3 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 8,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 3000, estimateResult: 3 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 9,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 4000, estimateResult: 3 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
    {
      id: 10,
      fileImg: '',
      detail: { fileNm: 'D46002200.STEP', status: 3, estimateTotalPrice: 5000, estimateResult: 3 },
      estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
    },
  ];

  useEffect(() => {
    setLoading(true);
    settingCad3dDatas();
  }, []);

  const settingCad3dDatas = async () => {
    let cad3dDatas = await getCad3dDatas(0, 0, 'ASC', '', {});
    if (cad3dDatas) {
      cad3dDatas = cad3dDatas.data;
      setCad3dDataLst(cad3dDatas);
      setLoading(false);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Shows a "copy" cursor when dragging over the component
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileUpload = async (excelFiles) => {
    if (excelFiles?.length > 0) {
      const cpcad3dDataLst = [...cad3dDataLst];
      const latestKey = cad3dDataLst.length > 0 ? Math.max(...cad3dDataLst.map((item) => item.id)) : 0;
      excelFiles?.map((file, i) => {
        let newKey = latestKey + i + 1;
        const uploadedFile = {
          id: newKey,
          fileImg: '',
          detail: { fileNm: file.name, status: 3, estimateTotalPrice: 0, estimateResult: 3 },
          estimateCondition: JSON.parse(JSON.stringify(estimateConditionDefault)),
        };
        cpcad3dDataLst?.push(uploadedFile);
      });
      setCad3dDataLst(cpcad3dDataLst);
    } else {
      alert('Please select a file to upload.');
      return;
    }
    // try {
    //   // const formData = new FormData();
    //   // formData.append('file', selectedFile);

    //   // const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}CompanySetting/uploadFile`;

    //   // Add authorization header with bearer token
    //   // const accessToken = getAccessToken();
    //   // const headers = {
    //   //   'Content-Type': 'multipart/form-data',
    //   //   Authorization: `Bearer ${accessToken}`,
    //   //   'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
    //   // };

    //   // const response = await axios.post(apiUrl, formData, { headers });

    //   // if (response && response.status === StatusCodes.OK) {
    //   complete('ファイルのアップロードは正常に完了しました。');
    //   // var uploadFiles = await getFileList();
    //   var uploadFiles = [];

    //   // setCad3dDataLst(uploadFiles);
    //   //setExcelFilesList([{ id: 0, title: '', useType: 0, fileNm: uploadFiles[0].value, fileId: '' }]);
    //   // } else if (
    //   //   response &&
    //   //   (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.CONFLICT)
    //   // ) {
    //   // authorizeError(ErrorMessage().E006);
    //   //   return;
    //   // } else if (response && response.status !== StatusCodes.OK) {
    //   // error(ErrorMessage()[response?.messageCode]);
    //   //   return;
    //   // }
    // } catch (internal_error) {
    //   error(internal_error.message);
    //   /* console.error('Error uploading file:', error.response ? error.response.data : error.message); */
    // }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    const excelFiles = droppedFiles?.filter(
      (file) => file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // for .xlsx files
    );
    handleFileUpload(excelFiles);
  };

  const browseExcelFiles = () => {
    excelFileRef.current.click();
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.estimateTotalPrice <= 0,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const closeEstimateDialog = () => setEstimateOpen(false);

  const settingOrderConditions = (index, colNm, value) => {
    const upOrderConditions = [...cad3dDataLst];
    if (upOrderConditions[index]) {
      upOrderConditions[index].orderConditions[colNm] = value;
      if (colNm === 'process') {
        if (value === 0) {
          upOrderConditions[index].orderConditions.processDetailOptions = Plating;
        } else {
          upOrderConditions[index].orderConditions.processDetailOptions = Painting;
        }
      } else if (colNm === 'deliveryDate') {
        upOrderConditions[index].orderConditions.deliveryTimeCls = value;
      }
    }
    setCad3dDataLst(upOrderConditions);
  };

  const columns = [
    {
      title: 'No',
      key: 'id',
      width: 50,
      fixed: 'left',
      render: (id, record, i) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {i + 1}
        </div>
      ),
    },
    {
      title: '画像',
      dataIndex: 'fileImg',
      width: 180,
      fixed: 'left',
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ textAlign: 'center', backgroundColor: '#808080b8', width: 80, height: 40 }}>
            {/* <Image preview={false} width={57} src={iq3Img_pmx1_1}></Image> */}
          </div>
          <div style={{ display: 'grid', marginLeft: 10 }}>
            <label>X:{record.sizeX}</label>
            <label>Y:{record.sizeY}</label>
            <label>Z:{record.sizeZ}</label>
          </div>
        </div>
      ),
    },
    {
      title: 'ファイル名',
      dataIndex: 'name',
      width: 200,
      fixed: 'left',
      render: (record) => (
        <div style={{ textAlign: 'left', marginLeft: 10, fontSize: 13 }}>
          <label>{record}</label>
        </div>
      ),
    },
    {
      title: '状態',
      dataIndex: 'status',
      width: 150,
      fixed: 'left',
      render: (status) => (
        <div className="circle-status-div">
          <Space size={'small'}>
            <Tooltip
              title={status === 0 ? UploadStatus?.find((com) => com.id === status)?.name : ''}
              placement="top"
              overlayClassName="tooltip-text"
            >
              <div className={status === 0 ? 'circle-status-blue' : 'circle-status'}></div>
            </Tooltip>
            <Tooltip
              title={status === 1 ? UploadStatus?.find((com) => com.id === status)?.name : ''}
              placement="top"
              overlayClassName="tooltip-text"
            >
              <div className={status === 1 ? 'circle-status-red' : 'circle-status'}></div>
            </Tooltip>
            <Tooltip
              title={status === 2 ? UploadStatus?.find((com) => com.id === status)?.name : ''}
              placement="top"
              overlayClassName="tooltip-text"
            >
              <div className={status === 2 ? 'circle-status-orange' : 'circle-status'}></div>
            </Tooltip>
            <Tooltip
              title={status === 3 ? UploadStatus?.find((com) => com.id === status)?.name : ''}
              placement="top"
              overlayClassName="tooltip-text"
            >
              <div className={status === 3 ? 'circle-status-green' : 'circle-status'}></div>
            </Tooltip>
          </Space>
        </div>
      ),
    },
    {
      title: '見積金額',
      width: 330,
      fixed: 'left',
      render: (text, record, i) => (
        <div style={{ textAlign: 'left', whiteSpace: 'pre-line', fontSize: 13, marginLeft: 30 }}>
          {record.estimateResult === 3 ? (
            <label>{'見積金額：' + record.estimateTotalPrice + '円（' + record.estimateUnitPrice + '円/個）'}</label>
          ) : (
            <label>{EstimateType[record.estimateResult]}</label>
          )}
        </div>
      ),
    },
    {
      title: '操作',
      width: 120,
      render: (text, record) => (
        <div style={{ textAlign: 'center', fontSize: 13 }}>
          <a target="_blank">
            <Button
              disabled={record.status === 3 ? false : true}
              key="historyBtn1"
              className={'estimate-cond-btn'}
              // loading={loading | { delay: 500 }}
              onClick={(e) => {
                setEstimateOpen(true);
              }}
            >
              <p>見積</p>
            </Button>
          </a>
        </div>
      ),
    },
    {
      title: '条件複製',
      width: 155,
      render: (text, record, i) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            id={'copy-paste-div' + i}
            className={record.id === copyEstimateCondition?.id ? 'copy-paste-div-selected' : 'copy-paste-div'}
            onClick={(e) => {
              setCopyEstimateCondition(JSON.parse(JSON.stringify(record)));
            }}
          >
            <Image preview={false} width={18} src={copy} className="copy-paste-div-pointer"></Image>
            <label className="copy-paste-div-pointer">コピー</label>
          </div>
          <div
            className="copy-paste-div copy-paste-div-mrleft"
            onClick={(e) => {
              if (Object.keys(copyEstimateCondition)?.length > 0) {
                let pasteEstimateCondition = JSON.parse(JSON.stringify(copyEstimateCondition));
                const upOrderConditions = [...cad3dDataLst];
                if (upOrderConditions[i]) {
                  upOrderConditions[i].orderConditions = pasteEstimateCondition.orderConditions;
                }
                setCad3dDataLst(upOrderConditions);
              }
            }}
          >
            <Image preview={false} width={18} src={paste} className="copy-paste-div-pointer"></Image>
            <label className="copy-paste-div-pointer">ペースト</label>
          </div>
        </div>
      ),
    },
    {
      title: '発注分類',
      width: 180,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <Select
            defaultValue={0}
            id={'order' + i}
            name={'order' + i}
            value={record.orderConditions?.order}
            onChange={(e) => {
              settingOrderConditions(i, 'order', e);
            }}
          >
            {OrderClass?.map((item, index) => (
              <Select.Option key={index} id={'order' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '個数',
      width: 120,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <Input
            style={{ height: 30, fontSize: 12, width: 100, textAlign: 'right' }}
            placeholder="1"
            value={record.orderConditions?.quantity}
            onChange={(e) => {
              if (record.orderConditions?.quantity !== e.target.value) {
                settingOrderConditions(i, 'quantity', e.target.value);
              }
            }}
          />
        </div>
      ),
    },
    {
      title: '材質',
      width: 180,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <Select
            defaultValue={0}
            id={'material' + i}
            name={'material' + i}
            value={record.orderConditions?.material}
            onChange={(e) => {
              settingOrderConditions(i, 'material', e);
            }}
          >
            {MaterialClass?.map((item, index) => (
              <Select.Option key={index} id={'material' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '処理',
      width: 330,
      render: (text, record, i) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select
            defaultValue={0}
            id={'process' + i}
            name={'process' + i}
            value={record.orderConditions.process}
            onChange={(e) => {
              settingOrderConditions(i, 'process', e);
            }}
          >
            {ProcessType?.map((item, index) => (
              <Select.Option key={index} id={'material' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          {(() => {
            const processDetailComp = [];
            let processDetailOptions = record.orderConditions.process === 0 ? Plating : Painting;
            processDetailComp.push(
              <Select
                defaultValue={0}
                id={'processDetail' + i}
                name={'processDetail' + i}
                className="copy-paste-div-mrleft"
                value={record.orderConditions?.processDetail}
                onChange={(e) => {
                  settingOrderConditions(i, 'processDetail', e);
                }}
              >
                {processDetailOptions?.map((item, index) => (
                  <Select.Option key={index} id={'processDetail' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            );
            return processDetailComp;
          })()}
        </div>
      ),
    },
    {
      title: '公差',
      width: 180,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <Select
            defaultValue={0}
            id={'tolerance' + i}
            name={'tolerance' + i}
            value={record.orderConditions?.tolerance}
            onChange={(e) => {
              settingOrderConditions(i, 'tolerance', e);
            }}
          >
            {ToleranceClass?.map((item, index) => (
              <Select.Option key={index} id={'tolerance' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '仕上げレベル',
      width: 180,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <Select
            defaultValue={0}
            id={'weldFinishLevel' + i}
            name={'weldFinishLevel' + i}
            value={record.orderConditions?.weldFinishLevel}
            onChange={(e) => {
              settingOrderConditions(i, 'weldFinishLevel', e);
            }}
          >
            {WeldFinishLevelClass?.map((item, index) => (
              <Select.Option key={index} id={'weldFinishLevel' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '最終用途',
      width: 180,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <Select
            defaultValue={0}
            id={'finalPurpos' + i}
            name={'finalPurpos' + i}
            value={record.orderConditions?.finalPurpos}
            onChange={(e) => {
              settingOrderConditions(i, 'finalPurpos', e);
            }}
          >
            {FinalPurposClass?.map((item, index) => (
              <Select.Option key={index} id={'finalPurpos' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '発注後納期',
      width: 180,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            defaultValue={0}
            id={'deliveryTimeCls' + i}
            name={'deliveryTimeCls' + i}
            value={record.orderConditions?.deliveryTimeCls}
            onChange={(e) => {
              settingOrderConditions(i, 'deliveryTimeCls', e);
            }}
          >
            {DeliveryTimeClass?.map((item, index) => (
              <Select.Option key={index} id={'deliveryTimeCls' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          {/* 日付 */}
          {cad3dDataLst[i].orderConditions?.deliveryTimeCls === 6 || showDatepicker ? (
            <div className="date-picker-div">
              {(() => {
                const dateComp = [];
                let curSelectDate = new Date();
                dateComp.push(
                  <>
                    <DatePicker
                      locale="ja"
                      open={cad3dDataLst[i].orderConditions?.deliveryTimeCls === 6}
                      onChange={(date) => {
                        curSelectDate = date;
                      }}
                      dateFormatCalendar="yyyy年 MM月"
                      dateFormat="yyyy/MM/dd"
                      popperPlacement="bottom-start"
                      renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
                          <CaretLeftOutlined
                            style={{ fontSize: 20, fontWeight: 'bold' }}
                            onClick={(e) => {
                              decreaseMonth();
                            }}
                          />
                          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                            {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                          </span>
                          <CaretRightOutlined
                            style={{ fontSize: 20, fontWeight: 'bold' }}
                            onClick={(e) => {
                              increaseMonth();
                            }}
                          />
                        </div>
                      )}
                    />
                    <div style={{ textAlign: 'right', marginTop: 8 }}>
                      <Button
                        style={{ border: 'none', fontWeight: 'bold', fontSize: 13 }}
                        onClick={(e) => {
                          setShowDatepicker(false);
                          let dayjsCurDate = curSelectDate ? formatDate(curSelectDate) : '';
                          settingOrderConditions(i, 'deliveryDate', dayjsCurDate);
                        }}
                      >
                        OK
                      </Button>
                      <Button
                        style={{ border: 'none', fontWeight: 'bold', fontSize: 13, marginLeft: 10 }}
                        onClick={(e) => {
                          settingOrderConditions(i, 'deliveryTimeCls', 0);
                          setShowDatepicker(false);
                        }}
                      >
                        キャンセル
                      </Button>
                    </div>
                  </>
                );
                return dateComp;
              })()}
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: '自由記入欄',
      width: 270,
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <TextArea
            style={{
              verticalAlign: 'Top',
              fontSize: '12px',
              height: '60px',
              borderRadius: '5px',
              padding: '10px',
            }}
            placeholder="ご質問やお悩みなどお気軽にご入力ください。"
            name="comment"
            id="comment"
            value={record.orderConditions?.comment}
            type="text"
            rows={4}
            onChange={(e) => {
              settingOrderConditions(i, 'comment', e.target.value);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 0 }}>
      <Layout className="history-layout">
        <Layout className="history-layout-tb">
          <div
            style={{
              display: 'grid',
              padding: 0,
              textAlign: 'center',
              marginBottom: 0,
            }}
          >
            <div
              className="circle"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <Image preview={false} width={74} src={upload_file} onClick={(e) => {}}></Image>
            </div>
            <label style={{ fontSize: '20px', fontWeight: 'bold', color: '#333333' }}>
              ここに3D CADファイルをドロップしてください。
            </label>
            <label style={{ marginTop: 7, fontSize: '13px', fontWeight: 'bold', color: '#333333' }}>
              ※複数ファイル同時にアップロード可能です。
            </label>
            <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>
              アップロード可能ファイル：.step .stp .X_T .sldprt .sldasm
            </label>
            <input
              type="file"
              multiple
              accept={allowedExtensions}
              ref={excelFileRef}
              style={{ display: 'none' }}
              onChange={(e) => {
                const uploadedFiles = [...e.target.files];
                handleFileUpload(uploadedFiles);
              }}
              onClick={(e) => (e.target.value = null)}
            />
            <Button
              key="upload"
              className="home-upload-btn"
              // loading={loading | { delay: 500 }}
              onClick={(e) => {
                browseExcelFiles();
              }}
            >
              または、こちらからアップロード
            </Button>
          </div>
          <div id="upload-file-div-id" className="upload-file-div">
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              scroll={{ y: 564, x: '10vw' }}
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={cad3dDataLst}
              className="upload-file-tb"
              pagination={false}
              loading={loading}
            />
          </div>
        </Layout>
        <EstimateFooter
          selectedRows={selectedRows}
          page={'home'}
          updateActionType={props?.updateActionType}
          columns={columns}
        />
      </Layout>
    </div>
  );
});

export default WithRouter(Home);
