/**
 * クラス名：条件を指定して見積り画面
 * 説明：見積りを行うための条件入力。
 * 作成者：ナンス
 * 作成日：2024/11/22
 * バージョン：1.0
 */
import { Form, Input, Button, Modal, Select } from 'antd';
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import Draggable from 'react-draggable';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import TextArea from 'antd/es/input/TextArea';
import { CaretLeftOutlined, CaretRightOutlined, CloseOutlined, RightOutlined } from '@ant-design/icons';

import '../assets/styles/common.css';
import { WithRouter } from '../components/WithRouter';
import { formatDate } from './common/Common';
// 日本語ロケールを登録
registerLocale('ja', ja);

const Estimate = forwardRef((props, ref) => {
  const formRef = React.useRef(null);
  const dialogRef = React.useRef(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [estimateCondition, setEstimateCondition] = useState({});

  useEffect(() => {
    if (props?.estimateOpen) {
      setEstimateCondition(props?.estimateCondition);
      if (props?.estimateCondition?.quantity === '') setBtnDisabled(true);
    }
  }, [props?.estimateOpen]);

  useImperativeHandle(ref, () => ({
    openDialog: openDialog,
  }));

  const dialogOk = () => {
    props?.closeEstimateDialog(false);
  };

  const dialogCancel = () => {
    props?.closeEstimateDialog(false);
  };

  const openDialog = () => {
    dialogRef.current.show(); // モーダルではなく、通常のダイアログとして表示
  };

  const orderClassifications = [
    { id: 0, name: '初回発注' },
    { id: 1, name: 'リピート発注' },
  ];

  const materials = [
    { id: 0, name: 'SPCC' },
    { id: 1, name: 'SECC' },
    { id: 2, name: 'SUS304' },
    { id: 3, name: 'SUS304鏡面' },
    { id: 4, name: 'SUS304HL' },
    { id: 5, name: 'AL5052' },
    { id: 6, name: 'その他（自由記入欄に記載）' },
  ];

  const process = [
    { id: 0, name: 'メッキ' },
    { id: 1, name: '塗装' },
  ];

  const comDiff = [
    { id: 0, name: 'A級' },
    { id: 1, name: 'B級（通常はB級）' },
    { id: 2, name: 'C級' },
  ];

  const weldFinish = [
    { id: 0, name: '#40' },
    { id: 1, name: '#80' },
    { id: 2, name: '#120' },
    { id: 3, name: '#180' },
  ];

  const latestProcess = [
    { id: 0, name: '内部部品' },
    { id: 1, name: '仕上げ要素' },
    { id: 2, name: '水漏れ不可' },
    { id: 3, name: '強度重要' },
    { id: 4, name: 'その他（自由記入欄に記載）' },
  ];

  const orderAfterDelivery = [
    { id: 0, name: '1週間以内' },
    { id: 1, name: '1週間～2週間' },
    { id: 2, name: '1週間～1か月' },
    { id: 3, name: '1か月～2か月' },
    { id: 4, name: '2か月以上' },
    { id: 5, name: '納期を指定する' },
  ];

  return (
    <Modal
      maskClosable={false}
      open={props?.estimateOpen}
      title={null}
      footer={null}
      bodyStyle={{ height: '88vh' }}
      width={600}
      onOk={dialogOk}
      onCancel={dialogCancel}
      closeIcon={<CloseOutlined style={{ color: '#333333' }} />}
      className={null}
      style={{ top: 0 }}
      centered={true}
      modalRender={(modal) => (
        <Draggable handle=".ant-modal-body">
          <div>{modal}</div>
        </Draggable>
      )}
    >
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <label style={{ fontSize: '18px', fontWeight: 'bold', color: '#333333', paddingTop: 5 }}>
          条件を指定して見積り
        </label>
        <div
          style={{
            display: 'grid',
            textAlign: 'center',
            justifyContent: 'center',
            padding: 0,
            height: '79vh',
            overflowY: 'auto',
            marginTop: 30,
          }}
        >
          <Form
            className="estimate-form"
            ref={formRef}
            layout="vertical"
            style={{
              maxWidth: '700px',
              height: '100%',
              width: '400px',
            }}
          >
            {/* 発注分類 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="order"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>発注分類</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="order"
                name="order"
                value={estimateCondition?.order}
                onChange={(e) => {
                  estimateCondition.order = e;
                }}
              >
                {orderClassifications?.map((item, index) => (
                  <Select.Option key={index} id={'order' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* 個数 */}
            <Form.Item
              id="quantity"
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="quantity"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>個数</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Input
                style={{ height: '30px', fontSize: 12 }}
                placeholder="1"
                value={estimateCondition?.quantity}
                onChange={(e) => {
                  if (estimateCondition.quantity !== e.target.value) {
                    estimateCondition.quantity = e.target.value;
                    if (estimateCondition.quantity === '') setBtnDisabled(true);
                    else setBtnDisabled(false);
                  }
                }}
              />
            </Form.Item>
            {/* 材質 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="material"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>材質</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="material"
                name="material"
                value={estimateCondition?.material}
                onChange={(e) => {
                  estimateCondition.material = e;
                }}
              >
                {materials?.map((item, index) => (
                  <Select.Option key={index} id={'material' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* 処理 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="process"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>処理</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="process"
                name="process"
                value={estimateCondition?.process}
                onChange={(e) => {
                  estimateCondition.process = e;
                }}
              >
                {process?.map((item, index) => (
                  <Select.Option key={index} id={'process' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* 公差 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="comDiff"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>公差</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="comDiff"
                name="comDiff"
                value={estimateCondition?.comDiff}
                onChange={(e) => {
                  estimateCondition.comDiff = e;
                }}
              >
                {comDiff?.map((item, index) => (
                  <Select.Option key={index} id={'comDiff' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* 仕上げレベル */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="weldFinish"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>仕上げレベル</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="weldFinish"
                name="weldFinish"
                value={estimateCondition?.weldFinish}
                onChange={(e) => {
                  estimateCondition.weldFinish = e;
                }}
              >
                {weldFinish?.map((item, index) => (
                  <Select.Option key={index} id={'weldFinish' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* 最終用途 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="latestProcess"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>最終用途</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="latestProcess"
                name="latestProcess"
                value={estimateCondition?.latestProcess}
                onChange={(e) => {
                  estimateCondition.latestProcess = e;
                }}
              >
                {latestProcess?.map((item, index) => (
                  <Select.Option key={index} id={'latestProcess' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* 発注後納期 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="orderAfterDelivery"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>発注後納期</label>
                  <div
                    style={{
                      backgroundColor: '#808080',
                      color: 'white',
                      fontSize: 11,
                      width: 35,
                      padding: 2,
                      textAlign: 'center',
                      borderRadius: 5,
                      marginLeft: 10,
                    }}
                  >
                    必須
                  </div>
                </>
              }
            >
              <Select
                defaultValue={0}
                id="orderAfterDelivery"
                name="orderAfterDelivery"
                value={estimateCondition?.orderAfterDelivery}
                onChange={(e) => {
                  setEstimateCondition({
                    orderAfterDelivery: e,
                  });
                  if (e === 5) {
                    setShowDatepicker(true);
                  } else {
                    setShowDatepicker(false);
                  }
                }}
              >
                {orderAfterDelivery?.map((item, index) => (
                  <Select.Option key={index} id={'order' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              {/* 日付 */}
              <div
                style={{
                  marginTop: 10,
                  textAlign: 'center',
                  display: showDatepicker ? '' : 'none',
                }}
              >
                <div className="react-datepicker-div">
                  <DatePicker
                    locale="ja"
                    open={showDatepicker}
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                    }}
                    dateFormatCalendar="yyyy年 MM月"
                    dateFormat="yyyy/MM/dd"
                    popperPlacement="bottom-start" // ボタンの下に表示
                    renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
                        <CaretLeftOutlined
                          style={{ fontSize: 20, fontWeight: 'bold' }}
                          onClick={(e) => {
                            decreaseMonth();
                          }}
                        />
                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                          {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </span>
                        <CaretRightOutlined
                          style={{ fontSize: 20, fontWeight: 'bold' }}
                          onClick={(e) => {
                            increaseMonth();
                          }}
                        />
                      </div>
                    )}
                  />
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      style={{ border: 'none', fontWeight: 'bold', fontSize: 13 }}
                      onClick={(e) => {
                        setEstimateCondition({
                          orderAfterDelivery: selectedDate ? formatDate(selectedDate) : '',
                          orderAfterDeliveryDate: selectedDate ? dayjs(selectedDate, 'YYYY-MM-DD') : '',
                        });
                        setShowDatepicker(false);
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      style={{ border: 'none', fontWeight: 'bold', fontSize: 13 }}
                      onClick={(e) => {
                        setEstimateCondition({
                          orderAfterDelivery: 5,
                          orderAfterDeliveryDate: '',
                        });
                        setShowDatepicker(false);
                      }}
                    >
                      キャンセル
                    </Button>
                  </div>
                </div>
              </div>
            </Form.Item>
            {/* 自由記入欄 */}
            <Form.Item
              style={{ textAlign: 'left', marginBottom: 18 }}
              name="comment"
              label={
                <>
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>自由記入欄</label>
                </>
              }
            >
              <TextArea
                style={{
                  verticalAlign: 'Top',
                  fontSize: '12px',
                  height: '100px',
                  // background: '#364d79',
                  borderRadius: '5px',
                  padding: '10px',
                  color: 'white',
                }}
                placeholder="ご質問やお悩みなどお気軽にご入力ください。"
                className={'input-non-editable'}
                name="comment"
                id="comment"
                value={estimateCondition?.comment}
                type="text"
                rows={4}
                onChange={(e) => {
                  estimateCondition.comment = e;
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div>
          <Button className="estimate-cond-btn" onClick={dialogOk} disabled={btnDisabled}>
            この条件で見積
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default WithRouter(Estimate);
