/**
 * クラス名：ログイン画面
 * 説明：システムログイン画面である。
 * 作成者：ナンス
 * 作成日：2024/11/14
 * バージョン：1.0
 */
import { Form, Input, Button, Image, Alert } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';

import logo from '../assets/images/logo.ico';
import { WithRouter } from '../components/WithRouter';
import '../assets/styles/common.css';
import { getCurrentUserInfo, isAlive, login } from './common/CommonAPI';
import { updateAccessToken } from './common/Common';
import { iQxRealGoku_AccessToken, iQxRealGoku_LoginUser } from './common/Constant';

const Login = forwardRef((props, ref) => {
  const formRef = React.useRef(null);
  let params = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState(params.has('ErrorMessage') ? params.get('ErrorMessage') : '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // ログイン後にユーザーを自動的にホーム画面にリダイレクトする処理
    isActiveToken();
  }, []);

  // ログインのトークン有効チェック処理
  const isActiveToken = async () => {
    try {
      let token = '';
      if (localStorage.getItem(iQxRealGoku_AccessToken)) {
        // ログインの有効チェック、
        token = localStorage.getItem(iQxRealGoku_AccessToken);
        let result = await isAlive(token);
        if (result?.statusCode == StatusCodes.OK) {
          // 正常の場合、
          token = result.updateToken;
          // セクションにトークン情報を保持する
          updateAccessToken(token);
          // 取得正常の場合、ログインユーザー情報を取得する
          result = await getCurrentUserInfo(token);
          if (result === '') {
            props.navigate('/home', { state: { fromLogin: true } });
          } else {
            // 異常の場合、
            setErrorMessage(result);
            return;
          }
        } else {
          // 異常の場合、
          setErrorMessage(result);
          return;
        }
      }
    } catch (error) {
      // 異常の場合、
      setErrorMessage(error.message);
      return error;
    }
  };

  // ログインボタンの押下
  const loginEvent = async (values) => {
    let loginId = values?.loginId;
    let password = values?.password;
    let remember = values?.rememberMe;
    setLoading(true);
    let loginInfo = await login(loginId, password, remember);
    if (loginInfo && loginInfo?.loginId > 0) {
      updateAccessToken(loginInfo?.updateToken);
      const loginUserInfo = {
        loginId: loginInfo?.loginId,
        userName: loginInfo?.userName,
      };
      localStorage.setItem(iQxRealGoku_LoginUser, encodeURI(JSON.stringify(loginUserInfo)));
      props.navigate('/home', { state: { fromLogin: true } });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="login-parent-div">
        <div className="login-child-div">
          <label className="login-main-title">1分で見積もり＆発注できる</label>
          <div className="login-main-title-parent-div">
            <div className="login-main-title-child-div">
              <Image preview={false} width={30} height={30} src={logo}></Image>
              <label className="login-child-title">RealGoku</label>
            </div>
          </div>
          <Form
            className="login-form"
            onFinish={loginEvent}
            ref={formRef}
            layout="vertical"
            initialValues={{
              loginId: '',
              password: '',
              rememberMe: true,
            }}
          >
            {/* 会員ID */}
            <Form.Item
              className="login-form-item-txt-align"
              style={{ marginBottom: 18 }}
              name="loginId"
              label={<label className="login-form-item-label">会員ID</label>}
            >
              <Input className="login-form-item-input" placeholder="" />
            </Form.Item>
            {/* パスワード */}
            <Form.Item
              className="login-form-item-txt-align"
              style={{ marginBottom: 30 }}
              name="password"
              label={<label className="login-form-item-label">パスワード</label>}
            >
              <Input className="login-form-item-input" type="password" placeholder="" />
            </Form.Item>
            {/* ログインボタン */}
            <Form.Item className="login-form-item-txt-align" style={{ marginBottom: 4 }}>
              <Button
                key="login"
                className="login-form-item-btn"
                type="primary"
                htmlType="submit2"
                loading={loading | { delay: 500 }}
              >
                ログイン
              </Button>
            </Form.Item>
            {/* 会員IDをお忘れの場合 */}
            <Form.Item name="companyIdForget" className="login-form-item-txt-align" style={{ marginBottom: -10 }}>
              <a href="#">
                <label className="login-form-item-ahref-label">会員IDをお忘れの場合</label>
              </a>
            </Form.Item>
            <Form.Item name="passwrodForget" className="login-form-item-txt-align" style={{ marginBottom: 18 }}>
              <a href="#">
                <label className="login-form-item-ahref-label">パスワードをお忘れの場合</label>
              </a>
            </Form.Item>
            {/* ログインボタン */}
            <Form.Item>
              <Button
                key="register"
                className="login-form-item-btn"
                style={{
                  backgroundColor: '#212529a8',
                }}
                type="primary"
                htmlType="submit2"
              >
                新規会員登録
              </Button>
            </Form.Item>
            {errorMessage != '' ? (
              <Form.Item name="errorMessage">
                <Alert className="login-form-item-alert" message={errorMessage} type="error" showIcon />
              </Form.Item>
            ) : (
              <></>
            )}
          </Form>
        </div>
      </div>
    </>
  );
});

export default WithRouter(Login);
