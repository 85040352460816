/**
 * 説明：Enum値定義
 * 作成者：ナンス
 * 作成日：2024/11/20
 * バージョン：1.0
 */

// 取引先種別
export const EstimateType = Object.freeze({
  0: 'ここに見積金額が表示されます', // 顧客
  1: '見積金額算出中（しばらくお待ちください）', // 仕入れ先
  2: '自動見積りができない加工です。別途担当者から\n2営業日以内に見積結果をご連絡いたします', // 外注先
});

export const OrderClass = [
  { id: 0, name: '初回発注' },
  { id: 1, name: 'リピート発注' },
];

export const MaterialClass = [
  { id: 0, name: 'SPCC' },
  { id: 1, name: 'SECC' },
  { id: 2, name: 'SUS304' },
  { id: 3, name: 'SUS304鏡面' },
  { id: 4, name: 'SUS304HL' },
  { id: 5, name: 'AL5052' },
  { id: 6, name: 'その他（自由記入欄に記載）' },
];

export const ProcessType = [
  { id: 0, name: 'メッキ' },
  { id: 1, name: '塗装' },
];

export const ToleranceClass = [
  { id: 0, name: 'A級' },
  { id: 1, name: 'B級（通常はB級）' },
  { id: 2, name: 'C級' },
];

export const WeldFinishLevelClass = [
  { id: 0, name: '#40' },
  { id: 1, name: '#80' },
  { id: 2, name: '#120' },
  { id: 3, name: '#180' },
];

export const FinalPurposClass = [
  { id: 0, name: '内部部品' },
  { id: 1, name: '仕上げ要素' },
  { id: 2, name: '水漏れ不可' },
  { id: 3, name: '強度重要' },
  { id: 4, name: 'その他（自由記入欄に記載）' },
];

export const DeliveryTimeClass = [
  { id: 0, name: '急ぎ' },
  { id: 1, name: '1週間以内' },
  { id: 2, name: '1週間～2週間' },
  { id: 3, name: '1週間～1か月' },
  { id: 4, name: '1か月～2か月' },
  { id: 5, name: '2か月以上' },
  { id: 6, name: '納期を指定する' },
];

export const Plating = [
  {
    name: 'クロメート',
    id: 0,
  },
  {
    name: '三価クロメート',
    id: 1,
  },
  {
    name: 'ユニクロ',
    id: 2,
  },
  {
    name: 'ニッケル',
    id: 3,
  },
  {
    name: '無電解ニッケル',
    id: 4,
  },
  {
    name: 'クロム',
    id: 5,
  },
  {
    name: 'アルマイト',
    id: 6,
  },
  {
    name: '亜鉛',
    id: 7,
  },
];
export const Painting = [
  {
    name: '粉体',
    id: 0,
  },
  {
    name: '溶剤',
    id: 1,
  },
  {
    name: 'エポキシ',
    id: 2,
  },
  {
    name: 'アクリル樹脂',
    id: 3,
  },
  {
    name: 'フッ素樹脂',
    id: 4,
  },
  {
    name: 'カチオン',
    id: 5,
  },
];

export const UploadStatus = [
  { id: 0, name: 'アップロード中' },
  { id: 1, name: '何かしらの理由でアップロードに失敗しました\n担当者から2営業日以内にご連絡いたします' },
  { id: 2, name: 'アップロード成功\n（容量オーバーのため自動見積対象外\n担当者から2営業日以内にご連絡いたします）' },
  { id: 3, name: 'アップロード成功' },
];
