/**
 * 説明：Constant値定義
 * 作成者：ナンス
 * 作成日：2024/12/20
 * バージョン：1.0
 */

// ログインユーザー情報
export const iQxRealGoku_LoginUser = 'iQxRealGoku_LoginUser';

// Sessionアクセストークン情報
export const iQxRealGoku_AccessToken = 'iQxRealGoku_AccessToken';

export const loginPath = process.env.REACT_APP_FRONTEND_URL;

export const allowedExtensions = ['.step', '.stp', '.X_T', '.sldprt', '.sldasm'];
