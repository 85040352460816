/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */

import { iQxRealGoku_AccessToken } from './Constant';

// セクションにトークン情報を更新する
export const updateAccessToken = (token) => {
  localStorage.setItem(iQxRealGoku_AccessToken, token);
};

// 最新のセクションにトークン情報を取得する
export const getAccessToken = () => {
  return localStorage?.getItem(iQxRealGoku_AccessToken);
};

export const secondsToHms = (totalIncSec) => {
  if (totalIncSec > 0) {
    var h = Math.floor(totalIncSec / 3600).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var m = Math.floor((totalIncSec % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var s = Math.round((totalIncSec % 3600) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return h + ':' + m + ':' + s;
  } else {
    return '00:00:00';
  }
};

export const setTimetoSec = (timeStr) => {
  if (timeStr == undefined || timeStr == '') return 0;
  var splitTime = timeStr.split(':');
  var seconds = +splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2];
  return seconds;
};

export const isNumber = (char) => {
  return /^\d$/.test(char);
};

// 現在の日付「YYYY/MM/DD」
export const today = new Date().toLocaleString('ja-JP', {
  timeZone: 'Asia/Tokyo',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

// 現在の日付「YYYY/MM/DD hhmmss」
export const todayTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'YYYYMMDD hhmmss',
  });
};

// YYYY/MM/DDへ変換
export function formatDate(date) {
  return new Date(date).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

// 現在のタイム「時分秒」
export const currentTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'hhmmss',
  });
};

// 現在の日付「年月日」
export const currentDate = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    format: 'YYYYMMDD',
  });
};

export function formatDateString(date, sep = '') {
  let dateString = date.replace(/[/:]+/g, '');
  dateString = dateString ? dateString : '';
  return dateString;
}

export const JPYs = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',
});
