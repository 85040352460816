/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2024/11/19
 * バージョン：1.0
 */
import { Layout, Image, Space, Drawer } from 'antd';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CalculatorOutlined,
  CloseOutlined,
  FileDoneOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';

import logo from '../assets/images/logo.ico';
import menu from '../assets/images/menu.png';
import EstimateHistory from '../views/EstimateHistory';
import ProcessCompleteInfo from '../views/common/ProcessCompleteInfo';
import Home from '../views/Home';
import { logOut } from '../views/common/CommonAPI';
import { iQxRealGoku_LoginUser } from '../views/common/Constant';

const { Header, Content } = Layout;

const MainLayout = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [actionType, setActionType] = useState('order');
  const [open, setOpen] = useState(false);
  const [loginUser, setLoginUser] = useState();

  useEffect(() => {
    let loginUser = JSON.parse(decodeURI(localStorage?.getItem(iQxRealGoku_LoginUser)));
    setLoginUser(loginUser);
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const updateActionType = (type) => {
    setActionType(type);
  };

  //　ログアウトボタンの押下
  const logoutEvent = async (e) => {
    e.preventDefault();
    const result = await logOut();
    if (result) {
      navigate('/login');
    }
  };

  return (
    <Layout className="main-layout">
      {/** ヘッダ部 */}
      <Header>
        <div className="header-menu-div">
          <Image
            preview={false}
            width={24}
            src={menu}
            className="menu-icon"
            onClick={(e) => {
              setOpen(true);
            }}
          ></Image>
          <div className="header-menu-div" style={{ marginLeft: '10px' }}>
            <Image preview={false} width={20} src={logo}></Image>
            <label className="header-logo-label">RealGoku</label>
          </div>
        </div>
        <div className="header-userNm-div">
          <UserOutlined className="header-icon" />
          <label className="header-icon-label">{loginUser ? loginUser?.userName : ''}</label>
          <div className="logout-div header-userNm-div" onClick={(e) => logoutEvent(e)} style={{ marginLeft: 13 }}>
            <LogoutOutlined className="header-icon" />
            <label className="logout-div-label header-icon-label">ログアウト</label>
          </div>
        </div>
      </Header>
      {/** マイン画面 */}
      <Layout className="main-layout">
        <Content>
          {/** 左メニューDrawer */}
          <Drawer title="Basic Drawer" onClose={onClose} open={open} placement="left">
            <div className="drawer-close-div">
              <CloseOutlined
                className="drawer-close-icon"
                onClick={(e) => {
                  setOpen(false);
                }}
              />
            </div>
            <div
              className="main-drawer-div"
              onClick={(e) => {
                navigate('/home');
                setOpen(false);
              }}
            >
              <Space size="middle">
                <CalculatorOutlined className="main-drawer-icon" />
                TOP＆見積・注文
              </Space>
            </div>
            <div
              className="main-drawer-div"
              onClick={(e) => {
                navigate('/home/:estimate-history', { state: { userName: loginUser ? loginUser?.userName : '' } });
                setOpen(false);
              }}
            >
              <Space size="middle">
                <FileDoneOutlined className="main-drawer-icon" />
                見積履歴
              </Space>
            </div>
            <div
              className="main-drawer-div"
              onClick={(e) => {
                setOpen(false);
              }}
            >
              <Space size="middle">
                <UserOutlined className="main-drawer-icon" />
                会員登録情報編集
              </Space>
            </div>
            <div
              className="main-drawer-div"
              onClick={(e) => {
                navigate('/home/:toppage');
                setOpen(false);
              }}
            >
              <Space size="middle">
                <SettingOutlined className="main-drawer-icon" />
                ユーザー設定
              </Space>
            </div>
          </Drawer>
          {/** TOP＆見積・注文画面/ 見積履歴画面 */}
          {location?.pathname == '/home' ? <Home updateActionType={updateActionType} /> : ''}
          {location?.pathname == '/home/:estimate-history' ? (
            <EstimateHistory updateActionType={updateActionType} />
          ) : (
            ''
          )}
          {location?.pathname == '/home/:process-complete-msg' ? <ProcessCompleteInfo actionType={actionType} /> : ''}
        </Content>
      </Layout>
    </Layout>
  );
});

export default MainLayout;
